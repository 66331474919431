import {CalendarDayV2, WEEKDAY} from '@wix/events-types'
import {EVENT_FILTER_TYPE} from '@wix/wix-events-commons-statics'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {setBaseEnvironment, setFormFactor} from '../../../../commons/actions/environment'
import {updateSiteSettings} from '../../../../commons/actions/site-settings'
import {RIBBON} from '../../../../commons/constants/ribbons'
import {CommonState, CommonStoreExtraArgs, DemoEvents} from '../../../../commons/types/state'
import type {WidgetComponentSettings} from '../../stylesParams'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from '../actions/calendar-layout'
import {updateStyles} from '../actions/component'
import {createEvent, shareEvent} from '../actions/event'
import {loadEventsPage} from '../actions/events'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from '../actions/layout'
import {openMembersModal} from '../actions/members'
import {navigateToPage, onLinkNavigatedToPage} from '../actions/navigate-to-page'
import {updateSettings} from '../actions/sdk'
import {DynamicVeloOverridesState} from '../reducers/velo-overrides'
import {Api} from '../utils/api'

export interface Actions {
  addCalendarMonth: typeof addCalendarMonth
  resetCalendar: typeof resetCalendar
  subCalendarMonth: typeof subCalendarMonth
  createEvent: typeof createEvent
  updateSiteSettings: typeof updateSiteSettings
  navigateToPage: typeof navigateToPage
  onLinkNavigatedToPage: typeof onLinkNavigatedToPage
  updateStyles: typeof updateStyles
  updateSettings: typeof updateSettings
  setBaseEnvironment: typeof setBaseEnvironment
  closeListLayoutItems: typeof closeListLayoutItems
  openListLayoutItem: typeof openListLayoutItem
  openMonthlyCalendarPopup: typeof openMonthlyCalendarPopup
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
  closeMonthlyCalendarEvent: typeof closeMonthlyCalendarEvent
  widgetLoaded: typeof widgetLoaded
  openMembersModal: typeof openMembersModal
  shareEvent: typeof shareEvent
  setFormFactor: typeof setFormFactor
  loadEventsPage: typeof loadEventsPage
}

export interface State extends CommonState {
  events: Events
  component: WidgetComponentConfig
  demoEvents: DemoEvents
  layout: Layout
  calendarLayout: CalendarLayout
  membersEnabled: boolean
  paidPlansEnabled: boolean
  owner: boolean
  memberLoggedIn: boolean
  dynamicVeloOverrides: DynamicVeloOverridesState
}

export interface WidgetComponentConfig {
  settings: WidgetComponentSettings
  params: Record<string, number | boolean>
  byStatus?: wix.events.editor.ByStatus
  byEventId?: wix.events.editor.ByEventId
  byCategoryId?: wix.events.editor.ByCategoryId
  id: string
}

export interface Events {
  events: ExtendedEvent[]
  moreLoading: boolean
  hasMore: boolean
  filterType: EVENT_FILTER_TYPE
}

export interface Layout {
  listItemOpened: string
  forceShowRibbon: RIBBON
}

export enum CalendarLoading {
  DEFAULT = 1,
  PREVIOUS,
  NEXT,
}

export interface CalendarLayout {
  referenceDate: string
  today: string
  month: Dictionary<{
    next: string
    previous: string
    fullMonthText: string
  }>
  monthly: MonthlyCalendarLayout
  weekDayNames: WEEKDAY[]
  loadedPages: string[]
  loading: CalendarLoading
  events: Dictionary<string[]>
  demoEvents: Dictionary<string[]>
  days: Dictionary<CalendarDayV2>
}

export interface MonthlyCalendarLayout {
  selectedDate: string
  selectedEventId: string
}

export type GetState = () => State

export interface StoreExtraArgs extends CommonStoreExtraArgs {
  serverApi: Api
  flowAPI: ControllerFlowAPI
}
