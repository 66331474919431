import {addQueryParams} from '@wix/panda-js-utils'
import {UrlMappingsKeys} from '@wix/url-mapper-utils'
import {
  EVENTS_APP_ID,
  EVENT_DETAILS_SECTION_ID,
  GROUPS_APP_DEF_ID,
  GROUPS_SECTION_ID,
  isExternal,
} from '@wix/wix-events-commons-statics'
import {IWixAPI} from '@wix/yoshi-flow-editor'
import {getSiteStructure, isPreview} from '../utils/wix-code-api'

export const navigateToDetailsPage = async (
  event: wix.events.Event,
  isDetailsPageEnabled: boolean,
  wixCodeApi: IWixAPI,
) => {
  if (!isExternal(event)) {
    const target = getDetailsTarget(event, isDetailsPageEnabled)
    const url = await getDetailsUrl(wixCodeApi)
    const queryParams = getQueryParams(wixCodeApi)
    if (wixCodeApi.location.to && url) {
      wixCodeApi.location.to(`${url}/${target}${queryParams}`)
    } else {
      return wixCodeApi.location.navigateToSection({
        sectionId: EVENT_DETAILS_SECTION_ID,
        state: target,
        queryParams: '',
      })
    }
  }
}

export const getDetailsUrl = async (wixCodeApi: IWixAPI) => {
  const url = await wixCodeApi.site.getSectionUrl({sectionId: EVENT_DETAILS_SECTION_ID, appDefinitionId: EVENTS_APP_ID})
  return url?.relativeUrl
}

export const getGroupPageUrl = async (wixCodeApi: IWixAPI) => {
  const url = await wixCodeApi.site.getSectionUrl({sectionId: GROUPS_SECTION_ID, appDefinitionId: GROUPS_APP_DEF_ID})
  return url?.relativeUrl
}

export const buildSiteGroupUrl = async (wixCodeApi: IWixAPI, slug: string) => {
  let url: string
  try {
    url = await wixCodeApi.location.buildCustomizedUrl?.(
      UrlMappingsKeys.GROUP,
      {
        groupId: slug,
        tabName: 'discussion',
      },
      {
        baseUrl: '',
      },
    )
  } catch (e) {
    console.warn('[Events:buildSiteGroupUrl] [location.buildCustomizedUrl] Failed')
  }
  if (!url) {
    const baseGroupsUrl = await getGroupPageUrl(wixCodeApi)
    url = `${baseGroupsUrl}/${slug}`
  }
  return url
}

export const navigateToGroup = async (slug: string, groupId: string, wixCodeApi: IWixAPI) => {
  if (wixCodeApi.location.to) {
    if (isPreview(wixCodeApi)) {
      const url = await getGroupPageUrl(wixCodeApi)
      wixCodeApi.location.to(`${url}?appSectionParams=${JSON.stringify({groupId})}`)
    } else {
      const url = await buildSiteGroupUrl(wixCodeApi, slug)
      wixCodeApi.location.to(`${url}?invite=true`)
    }
  }
}

export const getQueryParams = (wixCodeApi: IWixAPI) => addQueryParams('', wixCodeApi.location.query)

export const getDetailsTarget = (event: wix.events.Event, isDetailsPageEnabled: boolean) => {
  return `${event.slug}${isDetailsPageEnabled ? '' : '/form'}`
}

export const getPage = async (wixCodeApi: IWixAPI, pageId: string) => {
  const {pages} = await getSiteStructure(wixCodeApi)
  return pages.find(page => page.id === pageId)
}

export const getHomePage = async (wixCodeApi: IWixAPI) => {
  const {pages} = await getSiteStructure(wixCodeApi)
  return pages.find(page => page.isHomePage) || pages[0]
}
