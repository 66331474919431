import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {EMPTY_CATEGORY_FILTER} from '../../../../commons/constants/settings'
import settingsParams from '../../settingsParams'
import {State} from '../types/state'
import {getComponentCategoryIds} from './component'

export const getVeloCategoryId = (state: State) => state.dynamicVeloOverrides.categoryId

export const getDynamicCategoryIds = (state: State, flowAPI: ControllerFlowAPI) => {
  if (isVeloOverrideUsed(state)) {
    const override = getVeloCategoryId(state)
    return override ? [override] : []
  }

  const categoryId = flowAPI.settings.get(settingsParams.categoryId) as string
  if (categoryId === undefined) {
    return getComponentCategoryIds(state.component)
  }
  return categoryId === EMPTY_CATEGORY_FILTER ? null : [categoryId]
}

export const getDynamicCategoryId = (state: State, flowAPI: ControllerFlowAPI) =>
  getDynamicCategoryIds(state, flowAPI)?.[0] ?? null

export const isVeloOverrideUsed = (state: State) =>
  Boolean(Object.values(state.dynamicVeloOverrides).find(item => item !== null))
